import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const appointmentsService = {
  async getNutritionistAppointments(nutritionistId, from, to, page, size) {
    const token = localStorage.getItem("token");

    const response = await api.get(`/v1/appointments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        nutritionist_id: nutritionistId,
        page,
        size,
        from,
        to,
      },
    });

    return response.data;
  },

  async getAppointmentById(appointmentId) {
    const token = localStorage.getItem("token");

    const response = await api.get(`/v1/appointments/${appointmentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },

  async createAppointment(appointment) {
    const token = localStorage.getItem("token");

    const response = await api.post(`/v1/appointments`, appointment, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },

  async cancelAppointment(appointmentId) {
    const token = localStorage.getItem("token");

    const response = await api.patch(`/v1/appointments/${appointmentId}`,
      {
        status: 'CANCELED'
      }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },

  async rescheduleAppointment(appointmentId, dateTime) {
    const token = localStorage.getItem("token");

    const response = await api.patch(`/v1/appointments/${appointmentId}`,
      {
        date_time: dateTime
      }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

export default appointmentsService;