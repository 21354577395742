import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const nutritionistService = {
  async getPatients(nutritionistId, page, size, patientName) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get(`/v1/nutritionists/${nutritionistId}/patients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          patient_name: patientName,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar pacientes");
    }
  },

  async getNutritionistById(nutritionistId) {
    const token = localStorage.getItem("token");
    const response = await api.get(`/v1/nutritionists/${nutritionistId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },

  async getNutritionistAvailability(nutritionistId, from, to) {
    const token = localStorage.getItem("token");
    const response = await api.get(`/v1/nutritionists/${nutritionistId}/availabilities?from=${from}&to=${to}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },

  async getWeekAvailability(nutritionistId) {
    const token = localStorage.getItem("token");
    const response = await api.get(`/v1/nutritionists/${nutritionistId}/week-availability`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },

  async updateWeekAvailability(nutritionistId, weekAvailability) {
    const token = localStorage.getItem("token");
    const response = await api.put(`/v1/nutritionists/${nutritionistId}/week-availability`, weekAvailability, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

export default nutritionistService;