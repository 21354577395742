import "./TextInput.css";

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, InputGroup } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import OpenedEyeIcon from "../../assets/imgs/opened-eye-icon.png";
import ClosedEyeIcon from "../../assets/imgs/closed-eye-icon.png";

const TextInput = ({
  onChangeText,
  label,
  placeholder,
  value,
  style,
  type,
  minimum,
  maximum,
  dotSplitAtIndex,
  maxLength,
  disabled,
  secureTextEntry,
  invalid,
  invalidMessage,
  onBlur,
  size,
  description,
  maxFutureYears = 0,
}) => {
  const [showSecureText, setShowSecureText] = React.useState(false);
  const [showLabel, setShowLabel] = React.useState(value?.length > 0);

  const formatDateText = (text) => {
    const formatDate = (date) => {
      const formattedDate = date.replace(/[^0-9]/g, "");
      const dateNumber = Number(formattedDate);

      if (dateNumber > 31) {
        return "31";
      }

      if (dateNumber < 1) {
        return "01";
      }

      return formattedDate;
    }

    const formatMonth = (month) => {
      const formattedMonth = month.replace(/[^0-9]/g, "");
      const monthNumber = Number(formattedMonth);

      if (monthNumber > 12) {
        return "12";
      }

      if (monthNumber < 1) {
        return "01";
      }

      return formattedMonth;
    }

    const formatYear = (year) => {
      const formattedYear = year.replace(/[^0-9]/g, "");

      if (formattedYear.length === 1) {
        const yearNumber = Number(formattedYear);

        if (yearNumber > 2) {
          return "2";
        }

        if (yearNumber < 1) {
          return "1";
        }
      }

      if (formattedYear.length === 2) {
        const yearNumber = Number(formattedYear);

        if (yearNumber > 21) {
          return "21";
        }

        if (yearNumber < 19) {
          return "20";
        }

        return formattedYear;
      }

      if (formattedYear.length === 3) {
        const yearNumber = Number(formattedYear);

        if (yearNumber > 202) {
          return "202";
        }

        return formattedYear;
      }

      if (formattedYear.length === 4) {
        const yearNumber = Number(formattedYear);
        const maxYearAllowed = new Date().getFullYear() + maxFutureYears;

        if (yearNumber > maxYearAllowed) {
          return maxYearAllowed.toString();
        }

        return formattedYear;
      }

      return formattedYear;
    }

    if (text?.length > 10) {
      return text.slice(0, 10);
    }
    text = text.replace(/[^0-9/]/g, "");

    if (text?.length > 2) {
      const slashValue = text[2];
      if (slashValue !== "/") {
        text = text.slice(0, 2) + "/" + text.slice(2);
      }
      const date = text.split("/")[0];
      const formattedDate = formatDate(date);
      text = formattedDate + "/" + text.split("/").slice(1).join("/");
    }

    if (text?.length > 5) {
      const slashValue = text[5];
      if (slashValue !== "/") {
        text = text.slice(0, 5) + "/" + text.slice(5);
      }

      const month = text.split("/")[1];
      const formattedMonth = formatMonth(month);
      text = text.split("/")[0] + "/" + formattedMonth + "/" + text.split("/").slice(2).join("/");
    }

    if (text?.length > 6) {
      const year = text.split("/")[2];
      const formattedYear = formatYear(year);
      text = text.split("/").slice(0, 2).join("/") + "/" + formattedYear;
    }

    return text;
  }

  const formatTime = (text) => {
    const formatHours = (hour) => {
      const formattedHour = hour.replace(/[^0-9]/g, "");
      const hourNumber = Number(formattedHour);

      if (hourNumber > 23) {
        return "23";
      }

      if (hourNumber < 0) {
        return "00";
      }

      return formattedHour;
    }

    const formatMinutes = (minutes) => {
      const formattedMinutes = minutes.replace(/[^0-9]/g, "");
      const minutesNumber = Number(formattedMinutes);

      if (minutesNumber > 59) {
        return "59";
      }

      if (minutesNumber < 0) {
        return "00";
      }

      return formattedMinutes;
    }

    if (text?.length > 5) {
      return text.slice(0, 5);
    }
    text = text.replace(/[^0-9/]/g, "");

    if (text?.length > 2) {
      const slashValue = text[2];
      if (slashValue !== ":") {
        text = text.slice(0, 2) + ":" + text.slice(2);
      }
      const hours = text.split(":")[0];
      const formattedHours = formatHours(hours);
      const minutes = text.split(":")[1];
      text = formattedHours + ":" + minutes;
    }

    if (text?.length > 4) {
      const hours = formatHours(text.split(":")[0]);
      const minutes = text.split(":")[1];
      const formattedMinutes = formatMinutes(minutes);
      text = hours + ":" + formattedMinutes;
    }

    return text;
  }

  const handleChangeText = (text) => {
    if (text === "") {
      onChangeText("");
      setShowLabel("");
      return;
    }

    if (maxLength && text.length > maxLength) {
      return;
    }

    let filteredValue = text;

    if (type === "number") {
      let numberValue = Number(text.replace(/[^0-9]/g, ""));

      if (minimum > 0 && numberValue < minimum) {
        return;
      }

      if (maximum && numberValue > maximum) {
        numberValue = maximum;
      }

      filteredValue = numberValue.toString();
    }

    if (dotSplitAtIndex && dotSplitAtIndex > 0) {
      if (text.length <= dotSplitAtIndex + 1) {
        setShowLabel(filteredValue?.length > 0);
        onChangeText(filteredValue);
        return;
      }

      if (text.length <= dotSplitAtIndex + 2 && text.includes(".")) {
        const textWithoutDot = filteredValue.replace(".", "");
        setShowLabel(filteredValue?.length > 0);
        onChangeText(textWithoutDot);
        return;
      }

      const textWithoutDot = text.replace(".", "");

      const textWithDot =
        textWithoutDot.slice(0, dotSplitAtIndex) +
        "." +
        textWithoutDot.slice(dotSplitAtIndex);

      filteredValue = textWithDot;
    }

    if (type === "text") {
      filteredValue = text.replace(/[^a-zA-ZÁ-ÿ\s]/g, "");
    }

    if (type === "date") {
      filteredValue = formatDateText(text);
    }

    if (type === "time") {
      filteredValue = formatTime(text);
    }
    
    if (type === "password") {
      const invalidCharacters = [" "];
      const hasInvalidCharacters = invalidCharacters.some(character => text.includes(character));
      if (hasInvalidCharacters) {
        return;
      }

      filteredValue = text;
    }

    setShowLabel(filteredValue?.length > 0);
    onChangeText(filteredValue);
  };

  useEffect(() => {
    setShowLabel(value?.length > 0);
  }, [value]);

  const inputIsSmall = size === "small";
  const inputStyleForSmallSize = inputIsSmall ? "inputSection inputSectionSmall" : "inputSection";
  const inputStyleByInvalid = invalid ? `${inputStyleForSmallSize} inputSectionInvalid` : inputStyleForSmallSize;
  const inputStyleByActivity = disabled ? `${inputStyleByInvalid} inputSectionDisabled` : inputStyleByInvalid;
  const inputStyle = style ? `${inputStyleByActivity} ${style}` : inputStyleByActivity

  return (
    <>
      <div className={inputStyle}>
        <FormGroup className={`inputSectionInput ${disabled && "inputSectionInputDisabled"}`} as={Col}>
          <InputGroup>
            {!inputIsSmall && (
              <div className={"label__content"}>
                {label && showLabel && (
                  <div className="label">
                    <p>{label}</p>
                  </div>
                )}
              </div>
            )}
            <Form.Control
              value={value}
              onChange={(e) => {
                handleChangeText(e?.target?.value);
              }}
              onBlur={onBlur}
              type={type === "password" && secureTextEntry && !showSecureText ? "password" : "text"}
              placeholder={placeholder}
            ></Form.Control>
            {secureTextEntry && value && String(value).length > 0 && (
              <Button
                className="secureEye"
                onClick={() => setShowSecureText(prevState => !prevState)}
              >
                <img src={showSecureText ? OpenedEyeIcon : ClosedEyeIcon} className={"secureEye__icon"} />
              </Button>
            )}
          </InputGroup>
        </FormGroup>
      </div>
      {description && (
        <div className="input__description">
          {description}
        </div>
      )}
      {invalid && invalidMessage && (
        <div className="input__invalid-message">
          {invalidMessage}
        </div>
      )}
    </>
  );
}

TextInput.propTypes = {
  onChangeText: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.string,
  type: PropTypes.string,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  dotSplitAtIndex: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  secureTextEntry: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default TextInput;
