import "./DefaultTable.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";

const getColumnWidthPercentage = (columnsWidth, column) => {
  const columnWidth = columnsWidth ?? {
    IMAGE: 1.8,
    NAME: 3,
    CALORIES: 3,
    DATE: 3,
  }
  const toalColumns = Object.keys(columnWidth).reduce((acc, key) => acc + columnWidth[key], 0);

  return `${(columnWidth[column] / toalColumns) * 100}%`;
}

export const ColumnHeader = ({ title, column, columnsWidth }) => {
  return (
    <div
      className="column__header"
      style={{ width: getColumnWidthPercentage(columnsWidth, column) }}
    >
      {title}
    </div>
  );
}

export const ColumnValue = ({ column, children, columnsWidth, className }) => {
  return (
    <div
      className={`column__value ${className || ''}`}
      style={{ width: getColumnWidthPercentage(columnsWidth, column) }}
    >
      {children}
    </div>
  );
}

export const DefaultTableFooter = ({ children }) => {
  return (
    <div className="default-table__footer">
      {children}
    </div>
  );
}
