import "./Appointment.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import userService from "../../services/user-service";
import { GlobalContext } from "../GlobalContext";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";

import DefaultUserIcon from "../../assets/imgs/default-user-icon.png";

import DefaultButton from "../../components/DefaultButton/DefaultButton";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import TextInput from "../../components/TextInput/TextInput";

import { useParams } from "react-router-dom";
import appointmentsService from "../../services/appointments-service";
import { convertDateToString, formatTime, formatToDayMonthYearDate, monthName } from "../../utils/date-converter";
import NotificationBar from "../../components/NotificationBar/NotificationBar";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import StatusTag from "../../components/StatusTag/StatusTag";
import nutritionistService from "../../services/nutritionist-service";
import loginService from "../../services/login-service";

const Appointment = () => {
  const [appointmentId, setAppointmentId] = useState(useParams().id);
  const [currentSection, setCurrentSection] = useState("SHOW_MEAL_PLAN");
  const [appointment, setAppointment] = useState();
  const [mealPlan, setMealPlan] = useState();
  const [patient, setPatient] = useState();

  const [notificationBar, setNotificationBar] = useState();

  const [showCancelAppointmentModal, setShowCancelAppointmentModal] = useState(false);
  const [showRescheduleAppointmentModal, setShowRescheduleAppointmentModal] = useState(false);

  const [nextMonthAvailability, setNextMonthAvailability] = useState();
  const [rescheduleDate, setRescheduleDate] = useState();

  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const formatActivityFactor = (activityFactor) => {
    const formattedNames = {
      SEDENTARY: "Sedentário",
      LIGHTLY_ACTIVE: "Pouco ativo",
      MODERATELY_ACTIVE: "Moderadamente ativo",
      VERY_ACTIVE: "Muito ativo",
      EXTRA_ACTIVE: "Super ativo",
    }

    return formattedNames[activityFactor];
  }

  const fetchNextMonthAvailability = useCallback(async () => {
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const currentUser = await loginService.getCurrentUser();

    const availability = await nutritionistService.getNutritionistAvailability(
      currentUser.id,
      convertDateToString(new Date()),
      convertDateToString(nextMonth)
    );

    setNextMonthAvailability(availability);
  }, [])

  const rescheduleAppointment = useCallback(async () => {
    const newDate = `${rescheduleDate.date}T${rescheduleDate.from}`;

    try {
      setIsLoading(true);
      await appointmentsService.rescheduleAppointment(appointmentId, newDate);
      await fetchAppointment(appointmentId);
      setNotificationBar({ title: "Consulta remarcada com sucesso, e seu paciente foi notificado!" });
      setShowRescheduleAppointmentModal(false);
    } catch (error) {
      console.error(error);
      setNotificationBar({ title: "Ocorreu um erro ao remarcar a consulta. Tente novamente mais tarde." });
    } finally {
      setIsLoading(false);
    }
  }, [rescheduleDate]);

  const fetchPatient = useCallback(async (id) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const user = await userService.getUserById(id);
    setPatient(user);
  }, []);

  const fetchAppointment = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const appointmentFound = await appointmentsService.getAppointmentById(id);
      fetchPatient(appointmentFound?.patient?.id);
      setAppointment(appointmentFound);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [])

  const formatAppointmentDate = useCallback((date) => {
    const formattedDate = new Date(date);
    return `${formattedDate.getDate()} de ${monthName[formattedDate.getMonth() + 1]} de ${formattedDate.getFullYear()}`;
  }, []);

  const formatAppointmentTime = useCallback((date) => {
    const formattedDate = new Date(date);
    return `${formattedDate.getHours()}:${formattedDate.getMinutes().toString().padStart(2, "0")}`;
  }, []);

  const cancelAppointment = useCallback(async () => {
    try {
      setIsLoading(true);
      await appointmentsService.cancelAppointment(appointmentId);
      await fetchAppointment(appointmentId);
      setNotificationBar({ title: "Consulta desmarcada com sucesso!" });
      setShowCancelAppointmentModal(false);
    } catch (error) {
      console.error(error);
      setNotificationBar({ title: "Ocorreu um erro ao desmarcar a consulta. Tente novamente mais tarde." });
    } finally {
      setShowCancelAppointmentModal(false);
      setIsLoading(false);
    }
  }, [appointmentId, fetchAppointment]);
  
  const sumAppointmentTime = useCallback((time) => {
    const [hours, minutes] = time.split(":");

    const dateWithTime = new Date();
    dateWithTime.setHours(hours);
    dateWithTime.setMinutes(minutes);

    const appointmentDurationInMinutes = 50;

    const newDate = new Date(dateWithTime.getTime() + appointmentDurationInMinutes * 60000);

    const newHours = newDate.getHours().toString().padStart(2, "0");
    const newMinutes = newDate.getMinutes().toString().padStart(2, "0");

    return `${newHours}:${newMinutes}`;
  });

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("patient");
    Promise.resolve(fetchAppointment(appointmentId));
    Promise.resolve(fetchNextMonthAvailability());
  }, []);

  return (
    <>
      <div className="appointment">
        <div className="appointment__content">
          <div className="appointment__content__header">
            <a href="/appointments" className="appointment__content__header__previous">
              Consultas
            </a>
            <div className="appointment__content__header__divisor">
              <img src={ChevronRightIcon} alt="Chevron Right Icon" />
            </div>
            <div className="appointment__content__header__name">
              <p>{patient?.name}</p>
            </div>
          </div>
          {patient && (
            <div className="appointment__container">
              <div className="appointment__patient">
                <div className="patient__details__name">
                  <div className="patient__details__name__avatar">
                    <img src={patient?.photo_url ?? DefaultUserIcon} alt="User Icon" />
                  </div>
                  <h2>{patient?.name}</h2>
                </div>
                <div className="patient__details__actions">
                  {mealPlan && (
                    <>
                      <DefaultButton
                        title={currentSection === "SHOW_MEAL_PLAN" ? "Editar plano alimentar" : "Salvar plano alimentar"}
                        type="primary"
                        onClick={() => {
                          setCurrentSection(currentSection === "SHOW_MEAL_PLAN" ? "EDIT_MEAL_PLAN" : "SHOW_MEAL_PLAN");
                        }}
                        size="small"
                      />
                      <DefaultButton
                        title="Desativar plano alimentar"
                        type="secondary"
                        onClick={() => { }}
                        size="small"
                      />
                    </>
                  )}
                </div>
                <div className="patient__details__personal">
                  <div className="patient__details__personal__title">
                    <h3>Informações pessoais</h3>
                  </div>
                  <div className="patient__details__personal__forms">
                    <TextInput
                      label="Data de nascimento"
                      placeholder="Data de nascimento"
                      value={patient?.birth_date}
                    />
                    <div className="patient__details__personal__forms__measurements">
                      <TextInput
                        label="Peso"
                        placeholder="Peso"
                        value={`${patient?.body_measurement?.weight ?? ''}`}
                      />
                      <TextInput
                        label="Altura"
                        placeholder="Altura"
                        value={`${patient?.body_measurement?.height || ''}`}
                      />
                    </div>
                    <DefaultDropdown
                      label="Nível de atividade física"
                      value={formatActivityFactor(patient?.physical_activity_factor)}
                      placeholder="Nível de atividade física"
                      options={[
                        { value: "SEDENTARY", label: "Sedentário" },
                        { value: "LIGHTLY_ACTIVE", label: "Pouco ativo" },
                        { value: "MODERATELY_ACTIVE", label: "Moderadamente ativo" },
                        { value: "VERY_ACTIVE", label: "Muito ativo" },
                        { value: "EXTRA_ACTIVE", label: "Super ativo" },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="appointment__details">
                <div className="appointment__details__content">
                  <div className="appointment__details__title">
                    <h3>Detalhes da consulta</h3>
                  </div>
                  <div className="appointment__details__when">
                    <div className="appointment__details__when__title">
                      <h4>Quando</h4>
                    </div>
                    <div className="appointment__details__when__date">
                      <p>{formatAppointmentDate(appointment?.start_date_time)}</p>
                    </div>
                  </div>
                  <div className="appointment__details__time">
                    <div className="appointment__details__time__title">
                      <h4>Horário</h4>
                    </div>
                    <div className="appointment__details__time__hour">
                      <p>{formatAppointmentTime(appointment?.start_date_time)} às {formatAppointmentTime(appointment?.end_date_time)}</p>
                    </div>
                  </div>
                  <div className="appointment__details__where">
                    <div className="appointment__details__where__title">
                      <h4>Local</h4>
                    </div>
                    <div className="appointment__details__where__address">
                      <p>Rua do Nutricionista, 123</p>
                    </div>
                  </div>
                  <div className="appointment__details__status">
                    <div className="appointment__details__status__title">
                      <h4>Status</h4>
                    </div>
                    <div className="appointment__details__status__text">
                      {appointment?.status === "CANCELED" ? (
                        <StatusTag title="Cancelada" color="red" size="small" />
                      ) : (
                        <StatusTag title="Confirmada" color="green" size="small" />
                      )}
                    </div>
                  </div>
                </div>
                {appointment?.status !== "CANCELED" && (
                  <div className="appointment__details__actions">
                    <div className="appointment__details__actions__cancel">
                      <DefaultButton
                        title="Desmarcar"
                        type="secondary"
                        onClick={() => setShowCancelAppointmentModal(true)}
                        size="small"
                      />
                    </div>
                    <div className="appointment__details__actions__reschedule">
                      <DefaultButton
                        title="Remarcar"
                        type="primary"
                        onClick={() => setShowRescheduleAppointmentModal(true)}
                        size="small"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <DefaultModal
        title="Desmarcar consulta"
        showModal={showCancelAppointmentModal}
        onClose={() => setShowCancelAppointmentModal(false)}
        className="manage-availability-modal"
      >
        <div className="manage-availability-modal__content">
          <div className="manage-availability-modal__description">
            <p>Você tem certeza que deseja desmarcar esta consulta? O paciente será notificado sobre o cancelamento, e esta ação não poderá ser desfeita.</p>
          </div>
          <div className="manage-availability-modal__actions">
            <div className="manage-availability-modal__button">
              <DefaultButton
                title="Voltar"
                type="secondary"
                onClick={() => setShowCancelAppointmentModal(false)}
                size="small"
              />
            </div>
            <div className="manage-availability-modal__button">
              <DefaultButton
                title="Desmarcar"
                type="primary"
                onClick={() => cancelAppointment()}
                size="small"
              />
            </div>
          </div>
        </div>
      </DefaultModal>
      <DefaultModal
        title="Remarcar consulta"
        description="Escolha uma nova data e hora para a consulta"
        showModal={showRescheduleAppointmentModal}
        onClose={() => setShowRescheduleAppointmentModal(false)}
        className="manage-availability-modal"
      >
        <div className="manage-availability-modal__content">
          <div className="reschedule-modal__date">
            <DefaultDropdown
              placeholder={appointment ? formatToDayMonthYearDate(appointment?.start_date_time?.split("T")[0]) : ''}
              options={nextMonthAvailability?.days?.filter((day) => day.available_hours?.length > 0).map((availability) => {
                return {
                  value: availability.date,
                  label: formatToDayMonthYearDate(availability.date),
                }
              }) ?? []}
              value={rescheduleDate?.date ? formatToDayMonthYearDate(rescheduleDate?.date) : ''}
              onSelect={(date) => {
                setRescheduleDate({ date });
              }}
            />
          </div>
          <div className="reschedule-modal__periods">
            <DefaultDropdown
              placeholder={appointment ? formatAppointmentTime(appointment?.start_date_time) : ''}
              className="reschedule-modal__time"
              options={
                rescheduleDate?.date ?
                  nextMonthAvailability?.days?.
                    find((day) => day.date === rescheduleDate?.date)?.
                    available_hours.map((hour) => {
                      return {
                        value: hour,
                        label: formatTime(hour),
                      }
                    }) :
                  []
              }
              value={rescheduleDate?.from ? formatTime(rescheduleDate?.from) : ''}
              onSelect={(from) => {
                const to = sumAppointmentTime(from);
                setRescheduleDate({ ...rescheduleDate, from, to });
              }}
            />
            <div className="reschedule-modal__time-divisor">
              <p>Até</p>
            </div>
            <DefaultDropdown
              placeholder={appointment ? formatAppointmentTime(appointment?.end_date_time) : ''}
              className="reschedule-modal__time"
              options={[]}
              value={rescheduleDate?.to ? rescheduleDate.to : ''}
              disabled
            />
          </div>
        </div>
        <div className="manage-availability-modal__actions">
          <div className="manage-availability-modal__button">
            <DefaultButton
              title="Voltar"
              type="secondary"
              onClick={() => setShowCancelAppointmentModal(false)}
              size="small"
            />
          </div>
          <div className="manage-availability-modal__button">
            <DefaultButton
              title="Confirmar"
              type="primary"
              onClick={() => rescheduleAppointment()}
              size="small"
            />
          </div>
        </div>
      </DefaultModal>
      <NotificationBar
        show={notificationBar?.title}
        title={notificationBar?.title}
        durationInSeconds={4}
        onClose={() => setNotificationBar(null)}
      />
    </>
  );
};

export default Appointment;

Appointment.propTypes = {};
