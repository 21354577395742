import "./TopMenu.css";
import React, { useContext, useEffect, useRef, useState } from "react";

import FoodIcon from "../../assets/imgs/food-icon.svg";
import FoodSelectedIcon from "../../assets/imgs/food-selected-icon.svg";
import DashboardIcon from "../../assets/imgs/dashboard-icon.svg";
import DashboardSelectedIcon from "../../assets/imgs/dashboard-selected-icon.svg";
import TextLogo from "../../assets/imgs/text-logo.svg";
import NotificationIcon from "../../assets/imgs/notification.svg";

import loginService from "../../services/login-service";
import { Dropdown } from "react-bootstrap";

const ADMINISTRATOR_ROLE = "ROLE_ADMIN";
const NUTRITIONIST_ROLE = "ROLE_NUTRITIONIST";

const NutritionistMenuSection = () => {
  const nutritionistMenuItems = [
    {
      title: "Dashboard",
      screen: "home",
    },
    {
      title: "Consultas",
      screen: "appointments",
    },
    {
      title: "Pacientes",
      screen: "patients",
    },
  ];

  const currentPage = window.location.pathname.split("/")[1];

  return (
    <div className="top-menu__nutritionist__items">
      {nutritionistMenuItems.map((item, index) => (
        <a
          key={index}
          href="javascript:;"
          className={`top-menu__nutritionist__item ${currentPage === item.screen && "top-menu__nutritionist__item--selected"}`}
          onClick={() => { window.location.href = `/${item.screen}` }}
        >
          {item.title}
        </a>
      ))}
    </div>
  )
};

const NamingAvatar = ({ userName }) => {
  const names = userName?.split(" ");
  if (!names) {
    return <></>
  }
  const initials = names?.length > 1 ? names[0].charAt(0) + names[names.length - 1].charAt(0) : names[0].charAt(0);

  return (
    <a href="javascript:;" className="top-menu__user__icon__initials">
      {initials}
    </a>
  )
}

const UserAvatarSection = ({ user }) => {
  const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);

  const additionalOptions = [
    {
      title: "Meu perfil",
      onClick: () => {
        window.location.href = "/profile";
      },
    },
    {
      title: "Sair da conta",
      onClick: () => {
        loginService.logout();
        window.location.href = "/login";
      },
    }
  ];

  return (
    <div className="top-menu__user">
      {user?.photo_url ? (
        <a className="top-menu__user__icon" href="javascript:;" onClick={() => {
          setOpenOptionsDropdown(prevState => !prevState);
        }}>
          <img src={user?.photo_url} alt="User Icon" />
        </a>
      ) : (
        <NamingAvatar userName={user?.name} />
      )}
      <Dropdown
        show={openOptionsDropdown}
        onToggle={() => setOpenOptionsDropdown(prevState => !prevState)}
        className="top-menu__additional-options"
      >
        <Dropdown.Menu bsPrefix="dropdown-menu top-menu__additional-options__dropdown">
          {additionalOptions?.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={item.onClick}
              className={`top-menu__additional-options__dropdown__item ${item.className || ''}`}
            >
              {item.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
};

const NotificationsSection = () => {
  const [notificationIsOpened, setNotificationIsOpened] = useState(false);
  const [closeNotification, setCloseNotification] = useState(false);
  const notificationRef = useRef(null);
  const notificationBellRef = useRef(null);

  const notificationText = {
    APPOINTMENT_REFUSED: "recusou o agendamento para o dia 12/09.",
    APPOINTMENT_CONFIRMED: "confirmou o agendamento para o dia 07/09.",
    RELATIONSHIP_ACCEPTED: "aceitou o acompanhamento nutricional.",
    RELATIONSHIP_REFUSED: "recusou o acompanhamento nutricional."
  }
  
  const notifications = [
    {
      user: {
        photo_url: "https://lorie-s3-bucket.s3.amazonaws.com/avatar-images/03d2a105-8990-4cfc-979d-013e6567b7f4_man-white-boy-18.png",
        name: "Richard Nascimento"
      },
      code: "APPOINTMENT_REFUSED",
      time: "5 dias atrás",
      status: "UNREAD"
    },
    {
      user: {
        photo_url: "https://lorie-s3-bucket.s3.amazonaws.com/avatar-images/bc27691f-7dc9-4854-b539-86616ed3d788_man-beard-glasses-sr-40.png",
        name: "Jorge Silva"
      },
      code: "APPOINTMENT_CONFIRMED",
      time: "1 semana atrás",
      status: "UNREAD"
    },
    {
      user: {
        photo_url: "https://lorie-s3-bucket.s3.amazonaws.com/avatar-images/d0800a15-b192-40d9-9d6d-6d9928b1cc67_man-black-30.png",
        name: "Ricardo Oliveira"
      },
      code: "RELATIONSHIP_ACCEPTED",
      time: "2 semanas atrás",
      status: "READ"
    },
    {
      user: {
        photo_url: "https://lorie-s3-bucket.s3.amazonaws.com/avatar-images/4b539caf-6d39-4d87-83d0-85820793f023_man-black-beard-35.png",
        name: "Guilherme Santos"
      },
      code: "RELATIONSHIP_REFUSED",
      time: "3 semanas atrás",
      status: "READ"
    },
    {
      user: {
        photo_url: "https://lorie-s3-bucket.s3.amazonaws.com/avatar-images/0720abf1-886b-4972-849b-f37a3dd1f769_man-black-curly-redhead-25.png",
        name: "Michel Lima"
      },
      code: "RELATIONSHIP_ACCEPTED",
      time: "1 mês atrás",
      status: "READ"
    },
    {
      user: {
        photo_url: "https://lorie-s3-bucket.s3.amazonaws.com/avatar-images/0720abf1-886b-4972-849b-f37a3dd1f769_man-black-curly-redhead-25.png",
        name: "Michel Lima"
      },
      code: "RELATIONSHIP_ACCEPTED",
      time: "1 mês atrás",
      status: "READ"
    }
  ]

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!notificationIsOpened) {
        return
      }

      if (notificationRef.current && !notificationBellRef.current.contains(event.target) && !notificationRef.current.contains(event.target)) {
        setCloseNotification(true);
        setTimeout(() => {
          setNotificationIsOpened(false);
          setCloseNotification(false);
        }, 100);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [notificationIsOpened]);

  return (
    <>
      <a 
        href="javascript:;"
        className={`top-menu__notifications ${notificationIsOpened ? 'top-menu__notifications--opened' : ''}`}
        onClick={() => {
          setNotificationIsOpened(prevState => {
            if (closeNotification) {
              return prevState;
            }

            if (prevState) {
              setCloseNotification(true);
              setTimeout(() => {
                setNotificationIsOpened(false);
                setCloseNotification(false);
              }, 100);
              return prevState;
            }
            return !prevState;
          })
        }}
        ref={notificationBellRef}
      >
        <img src={NotificationIcon} alt="Notification Icon" />
      </a>
      {notificationIsOpened && (
        <div className={`top-menu__notifications__box ${closeNotification ? 'top-menu__notifications__box--hidden' : ''}`} ref={notificationRef}>
          <div className="top-menu__notifications__box__header">
            <p className="top-menu__notifications__box__title">Notificações</p>
            <div className="top-menu__notifications__box__options">
              <a href="jasvascript:;" className="top-menu__notifications__box__option top-menu__notifications__box__option--selected">
                <p>Tudo</p>
              </a>
              <a href="jasvascript:;" className="top-menu__notifications__box__option">
                <p>Não lidas</p>
              </a>
            </div>
          </div>
          <div className="top-menu__notifications__box__items">
            {notifications.map((notification, index) => (
              <div key={index} className="top-menu__notifications__box__item">
                <div className="top-menu__notifications__box__item__avatar">
                  <img src={notification.user.photo_url} alt="User Avatar" />
                </div>
                <div className="top-menu__notifications__box__item__content">
                  <p>
                    <span>{notification.user.name}</span> {notificationText[notification.code]}
                  </p>
                  <p className="top-menu__notifications__box__item__time">{notification.time}</p>
                </div>
                {notification.status === 'UNREAD' && <div className="top-menu__notifications__box__item__unread-circle" />}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

const TopMenu = () => {
  const [mainRole, setMainRole] = useState();
  const [currentUser, setCurrentUser] = useState();

  const fetchCurrentUser = async () => {
    const user = await loginService.getCurrentUser();
    setCurrentUser(user);
  };

  useEffect(() => {
    Promise.resolve(fetchCurrentUser());
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    var role = "ROLE_USER";
    var mappedRoles = new Map();

    for (let role of currentUser?.roles) {
      mappedRoles.set(role.name, role);
    };

    if (mappedRoles.has("ROLE_NUTRITIONIST")) {
      role = "ROLE_NUTRITIONIST";
    } else if (mappedRoles.has("ROLE_ADMIN")) {
      role = "ROLE_ADMIN";
    };

    setMainRole(role);
  }, [currentUser]);

  const currentPage = window.location.pathname.split("/")[1];

  const AdminScreensSection = () => (
    <div className="top-menu__items">
      <a href="/foodstuffs">
        <div
          className={`top-menu__items__item ${currentPage === "create-foodstuff" ||
            currentPage.startsWith("foodstuffs") && "top-menu__items__item--chosen"}`}
        >
          <div className="top-menu__items__item__icon">
            {currentPage === "create-foodstuff" || currentPage.startsWith("foodstuffs") ? (
              <img src={FoodSelectedIcon} alt="Dashboard selected icon" />
            ) : (
              <img src={FoodIcon} alt="Food icon" />
            )}
          </div>
        </div>
      </a>
      <a href="javascript:;" onClick={() => { window.location.href = "/home" }}>
        <div
          className={`top-menu__items__item ${currentPage === "home" && "top-menu__items__item--chosen"}`}
        >
          <div className="top-menu__items__item__icon">
            {currentPage === "home" ? (
              <img src={DashboardSelectedIcon} alt="Dashboard selected icon" />
            ) : (
              <img src={DashboardIcon} alt="Dashboard icon" />
            )}
          </div>
        </div>
      </a>
    </div>
  )

  const NutritionistMenu = ({ currentUser }) => {
    return (
      <div className="top-menu__shadow">
        <div className="top-menu">
          <div className="top-menu__left">
            <a className="top-menu__logo" href="/home">
              <img src={TextLogo} alt="Text Logo" />
            </a>
            <NutritionistMenuSection />
          </div>
          <div className="top-menu__right">
            <NotificationsSection />
            <UserAvatarSection user={currentUser} />
          </div>
        </div>
      </div>
    );
  }

  const AdministratorMenu = ({ currentUser } ) => {
    return (
      <div className="top-menu">
        <div className="top-menu__left">
          <a className="top-menu__logo" href="/home">
            <img src={TextLogo} alt="Text Logo" />
          </a>
        </div>
        <div className="top-menu__right">
          <AdminScreensSection />
          <UserAvatarSection user={currentUser} />
        </div>
      </div>
    )
  }

  return (
    <div className="top-menu__shadow">
      {mainRole === NUTRITIONIST_ROLE && <NutritionistMenu currentUser={currentUser} />}
      {mainRole === ADMINISTRATOR_ROLE && <AdministratorMenu currentUser={currentUser} />}
    </div>
  );
};

export default TopMenu;
