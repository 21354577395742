import "./Patient.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import loginService from "../../services/login-service";
import userService from "../../services/user-service";
import foodstuffService from "../../services/foodstuff-service";
import { GlobalContext } from "../GlobalContext";
import XSquareIcon from "../../assets/imgs/x-square-icon.svg";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";

import CaloriesIcon from "../../assets/imgs/calories-icon.svg";
import ProteinIcon from "../../assets/imgs/protein-icon.svg";
import CarbohydratesIcon from "../../assets/imgs/carbo-icon.svg";

import DefaultUserIcon from "../../assets/imgs/default-user-icon.png";
import MealPlanIcon from "../../assets/imgs/meal-plan-icon.svg";
import PinkDishIcon from "../../assets/imgs/pink-dish-icon.svg";

import DefaultButton from "../../components/DefaultButton/DefaultButton";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import TextInput from "../../components/TextInput/TextInput";

import { useParams } from "react-router-dom";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import mealPlanService from "../../services/meal-plan-service";
import NotificationBar from "../../components/NotificationBar/NotificationBar";

const mealOptions = [
  {
    value: "BREAKFAST",
    label: "Café da manhã",
  },
  {
    value: "MORNING_SNACK",
    label: "Lanche da manhã",
  },
  {
    value: "LUNCH",
    label: "Almoço",
  },
  {
    value: "AFTERNOON_SNACK",
    label: "Lanche da tarde",
  },
  {
    value: "DINNER",
    label: "Jantar",
  },
  {
    value: "SUPPER",
    label: "Ceia",
  },
]

const Patient = () => {
  const [currentUser, setCurrentUser] = useState();
  const [patientId, setPatientId] = useState(useParams().id);
  const [currentSection, setCurrentSection] = useState("SHOW_MEAL_PLAN");
  const [searchedFoodstuffs, setSearchedFoodstuffs] = useState([]);
  const [selectedFoodstuff, setSelectedFoodstuff] = useState();
  const [selectedMealPeriod, setSelectedMealPeriod] = useState();
  const [showAddFoodstuffModal, setShowAddFoodstuffModal] = useState(false);
  const [showDisableMealPlanModal, setShowDisableMealPlanModal] = useState(false);
  const [tempMealPlan, setTempMealPlan] = useState();
  const [mealPlan, setMealPlan] = useState();
  const [patient, setPatient] = useState();
  const [notificationBar, setNotificationBar] = useState();

  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const formatActivityFactor = (activityFactor) => {
    const formattedNames = {
      SEDENTARY: "Sedentário",
      LIGHTLY_ACTIVE: "Pouco ativo",
      MODERATELY_ACTIVE: "Moderadamente ativo",
      VERY_ACTIVE: "Muito ativo",
      EXTRA_ACTIVE: "Super ativo",
    }

    return formattedNames[activityFactor];
  }

  const formatMealName = (mealName) => {
    if (!mealName) {
      return '';
    }

    const formattedNames = {
      BREAKFAST: "Café da manhã",
      MORNING_SNACK: "Lanche da manhã",
      LUNCH: "Almoço",
      AFTERNOON_SNACK: "Lanche da tarde",
      DINNER: "Jantar",
      SUPPER: "Ceia",
    }

    return formattedNames[mealName] || mealName;
  }

  const getMeasurementPrefix = (unitOfMeasurement) => {
    const formattedNames = {
      GRAMS: "g",
      MILLILITERS: "ml",
      UNITS: "un",
    }

    return formattedNames[unitOfMeasurement] || unitOfMeasurement;
  }

  const formatFoodstuffTitle = (meal) => {
    const { foodstuff } = meal;
    if (foodstuff.unit_of_measurement === 'UNITS') {
      return `${meal.quantity} ${meal.foodstuff.name}`;
    }

    return `${foodstuff.baseline_weight}${getMeasurementPrefix(foodstuff.unit_of_measurement)} de ${foodstuff.name}`;
  }

  const fetchPatient = useCallback(async (id) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const user = await userService.getUserById(id);
    setPatient(user);

    const activeMealPlan = user?.meal_plans?.find((mealPlan) => mealPlan.status === "ACTIVE");
    setMealPlan(activeMealPlan);
  }, []);

  const fetchFoodstuffs = useCallback(async (name) => {
    const foodstuffsResponse = await foodstuffService.getFoodstuffs(0, 4, name);

    setSearchedFoodstuffs(foodstuffsResponse.content);
  }, []);

  const formatDateToAmericanPattern = (date) => {
    const currentDate = new Date(date);
    const currentDay = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
    const currentMonth = currentDate.getMonth() + 1 > 9 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`;
    const currentYear = currentDate.getFullYear() > 9 ? currentDate.getFullYear() : `0${currentDate.getFullYear()}`;

    return `${currentYear}-${currentMonth}-${currentDay}`;
  }

  const handleDisableMealPlan = useCallback(async (mealPlan) => {
    setIsLoading(true);

    const disableRequest = {
      ...mealPlan,
      patient_id: patientId,
      meal_periods: mealPlan.meal_periods.map((mealPeriod) => ({
        id: mealPeriod.id,
        period: mealPeriod.period,
        meal_foodstuffs: mealPeriod.meal_foodstuffs.map((meal) => ({
          id: meal.id,
          foodstuff_id: meal.foodstuff.id,
          baseline_weight: meal.foodstuff.baseline_weight,
          quantity: meal.foodstuff.quantity || 1,
        })),
      })),
      status: "INACTIVE",
    }

    try {
      const response = await mealPlanService.updateMealPlan(disableRequest);
      setMealPlan(response);
      setCurrentSection("SHOW_MEAL_PLAN");

      fetchPatient(patientId);

      setNotificationBar({ title: "Plano alimentar desativado com sucesso!" });
      setShowDisableMealPlanModal(false);
    } catch (error) {
      console.log("Erro ao desativar plano alimentar", error);
      setNotificationBar({ title: "Erro ao desativar plano alimentar" });
    } finally {
      setIsLoading(false);
    }
  }, [currentUser?.id, patientId]);

  const handleUpdateMealPlan = useCallback(async (mealPlan) => {
    setIsLoading(true);

    const updateRequest = {
      ...mealPlan,
      patient_id: patientId,
      meal_periods: mealPlan.meal_periods.map((mealPeriod) => ({
        id: mealPeriod.id,
        period: mealPeriod.period,
        meal_foodstuffs: mealPeriod.meal_foodstuffs.map((meal) => ({
          id: meal.id,
          foodstuff_id: meal.foodstuff.id,
          baseline_weight: meal.foodstuff.baseline_weight,
          quantity: meal.foodstuff.quantity || 1,
        })),
      })),
    }

    try {
      const response = await mealPlanService.updateMealPlan(updateRequest);
      setMealPlan(response);
      setCurrentSection("SHOW_MEAL_PLAN");

      setNotificationBar({ title: "Plano alimentar atualizado com sucesso" });
    } catch (error) {
      console.log("Erro ao atualizar plano alimentar", error);
      setNotificationBar({ title: "Erro ao atualizar plano alimentar" });
    } finally {
      setIsLoading(false);
    }
  }, [currentUser?.id, patientId]);

  const handleCreateMealPlan = useCallback(async (mealPlan) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const dateThreeMonthsLater = new Date();
    dateThreeMonthsLater.setMonth(dateThreeMonthsLater.getMonth() + 3);

    const startDate = formatDateToAmericanPattern(new Date());
    const endDate = formatDateToAmericanPattern(dateThreeMonthsLater);

    try {
      const response = await mealPlanService.createMealPlan(currentUser.id, patientId, {
        ...mealPlan,
        start_date: startDate,
        end_date: endDate,
      });
      console.log('response', response);
      setMealPlan(response);
      setCurrentSection("SHOW_MEAL_PLAN");

      setNotificationBar({ title: "Plano alimentar criado com sucesso" });
    } catch (error) {
      console.log("Erro ao criar plano alimentar", error);

      setNotificationBar({ title: "Erro ao criar plano alimentar" });
    } finally {
      setIsLoading(false);
    }
  }, [currentUser?.id, patientId, formatDateToAmericanPattern, mealPlan]);

  const availableMealOptions = mealOptions?.filter((option) => !tempMealPlan?.meal_periods?.find((mealPeriod) => mealPeriod.period === option.value));

  const getLastUpdateDateDescription = () => {
    if (!mealPlan) {
      return '';
    }

    const lastUpdateDate = new Date(mealPlan.updated_at);
    const currentDate = new Date();

    const differenceInDays = Math.floor((currentDate - lastUpdateDate) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return 'Última atualização hoje';
    }
    
    if (differenceInDays < 30) {
      return `Última atualização há ${differenceInDays} dias`;
    }

    const differenceInMonths = Math.floor(differenceInDays / 30);
    const differenteInMonthsRounded = parseInt(differenceInMonths);

    if (differenteInMonthsRounded === 1) {
      return 'Última atualização há 1 mês';
    }

    return `Última atualização há ${differenteInMonthsRounded} meses`;
  }

  const getSectionTitle = () => {
    const titleBySection = {
      SHOW_MEAL_PLAN: "Plano alimentar",
      CREATE_MEAL_PLAN: "Novo plano alimentar",
      EDIT_MEAL_PLAN: "Editar plano alimentar",
    }

    return titleBySection[currentSection];
  }

  const handleAddSelectedFoodstuffToMealPeriod = () => {
    const newMeal = {
      foodstuff_id: selectedFoodstuff.id,
      foodstuff: selectedFoodstuff,
      baseline_weight: selectedFoodstuff.baseline_weight,
      quantity: selectedFoodstuff.quantity || 1,
    };

    const newTempMealPlan = {
      ...tempMealPlan,
      meal_periods: tempMealPlan.meal_periods.map((mealPeriod) => mealPeriod.period === selectedMealPeriod ? { ...mealPeriod, meal_foodstuffs: [...mealPeriod.meal_foodstuffs, newMeal] } : mealPeriod),
    };

    setTempMealPlan(newTempMealPlan);
    setShowAddFoodstuffModal(false);
    setSelectedFoodstuff(undefined);
    setSelectedMealPeriod(undefined);
  }

  const handleRemoveFoodstuffFromMealPeriod = (mealPeriod, meal) => {
    const newFoodstuffs = mealPeriod.meal_foodstuffs.filter((item) => item.foodstuff.id !== meal.foodstuff.id);
    setTempMealPlan({
      ...tempMealPlan,
      meal_periods: tempMealPlan.meal_periods.map((item) => item.period === mealPeriod.period ? { ...item, meal_foodstuffs: newFoodstuffs } : item),
    });
  }

  const getTotalOfCalories = () => {
    const consideredMealPlan = tempMealPlan || mealPlan;
    let totalCalories = 0;

    if (!consideredMealPlan?.meal_periods) {
      return totalCalories;
    }

    for (let mealPeriod of consideredMealPlan?.meal_periods) {
      for (let meal of mealPeriod.meal_foodstuffs) {
        totalCalories += meal.foodstuff.nutrition_information.calories;
      }
    }

    return totalCalories;
  }

  const calculateTotalCalories = (mealPeriod) => mealPeriod.meal_foodstuffs
    .reduce((acc, meal) => acc + meal.foodstuff.nutrition_information.calories, 0)
    .toFixed(1)

  const calculateTotalProteins = (mealPeriod) => mealPeriod.meal_foodstuffs
    .reduce((acc, meal) => acc + meal.foodstuff.nutrition_information.proteins, 0)
    .toFixed(1)

  const calculateTotalCarbohydrates = (mealPeriod) => mealPeriod.meal_foodstuffs
    .reduce((acc, meal) => acc + meal.foodstuff.nutrition_information.carbohydrates, 0)
    .toFixed(1)

  const hasIncompleteMealPeriod = () => {
    if (!tempMealPlan || tempMealPlan?.meal_periods?.length === 0) {
      return true;
    }

    const somePeriodDoesNotHaveFoodstuffs = tempMealPlan?.meal_periods?.some((mealPeriod) => mealPeriod.meal_foodstuffs.length === 0);
    const somePeriodDoesNotHavePeriodName = tempMealPlan?.meal_periods?.some((mealPeriod) => !mealPeriod.period);
    return somePeriodDoesNotHaveFoodstuffs || somePeriodDoesNotHavePeriodName;
  }

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("patient");

    async function fetchCurrentUser() {
      if (!isLoading) {
        setIsLoading(true);
      }

      const userFound = await loginService.getCurrentUser();
      setCurrentUser(userFound);
    }

    async function fetchData() {
      setIsLoading(true);
      await Promise.all([
        fetchCurrentUser(),
        fetchPatient(patientId),
      ]);
      setIsLoading(false);
    }

    setIsLoading(true);
    fetchData();
    fetchFoodstuffs('');
  }, []);

  console.log('current section', currentSection);

  const Metric = ({ icon, title, value }) => {
    return (
      <div className="patient__monthly-summary__day__content__metric">
        <div className="patient__monthly-summary__day__content__metric__icon">
          <img src={icon} alt="Calorias" />
        </div>
        <div className="patient__monthly-summary__day__content__metric__info">
          <span className="patient__monthly-summary__day__content__metric__title">{value}</span>
          <span className="patient__monthly-summary__day__content__metric__subtitle">{title}</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="patient">
        <div className="patient__content">
          <div className="patient__content__header">
            <a href="/patients" className="patient__content__header__previous">
              Pacientes
            </a>
            <div className="patient__content__header__divisor">
              <img src={ChevronRightIcon} alt="Chevron Right Icon" />
            </div>
            <div className="patient__content__header__name">
              <p>{patient?.name}</p>
            </div>
          </div>
          {patient && (
            <div className="patient__container">
              <div className="patient__details">
                <div className="patient__details__name">
                  <div className="patient__details__name__avatar">
                    <img src={patient?.photo_url ?? DefaultUserIcon} alt="User Icon" />
                  </div>
                  <h2>{patient?.name}</h2>
                </div>
                <div className="patient__details__actions">
                  {mealPlan && (
                    <>
                      {currentSection === "SHOW_MEAL_PLAN" && (
                        <DefaultButton
                          title="Editar plano alimentar"
                          type="primary"
                          onClick={() => {
                            setCurrentSection("EDIT_MEAL_PLAN");
                            setTempMealPlan(mealPlan);
                            console.log('meal plan', mealPlan);
                          }}
                          size="small"
                          disabled={mealPlan?.meal_periods?.length === 0 || mealPlan?.meal_periods?.some((mealPeriod) => mealPeriod.meal_foodstuffs.length === 0 || !mealPeriod.period)}
                        />
                      )}
                      {(currentSection === "CREATE_MEAL_PLAN" || currentSection === "EDIT_MEAL_PLAN") && (
                        <DefaultButton
                          title="Salvar plano alimentar"
                          type="primary"
                          onClick={() => {
                            if (currentSection === "CREATE_MEAL_PLAN") {
                              handleCreateMealPlan(tempMealPlan);
                            } else if (currentSection === "EDIT_MEAL_PLAN") {
                              handleUpdateMealPlan(tempMealPlan);
                            } {
                              setMealPlan(tempMealPlan);
                              setCurrentSection("SHOW_MEAL_PLAN");
                            }
                          }}
                          size="small"
                          disabled={hasIncompleteMealPeriod()}
                        />
                      )}
                      <DefaultButton
                        title={patient?.meal_plans?.length > 0 && currentSection === "SHOW_MEAL_PLAN" ? "Desativar plano alimentar" : "Cancelar"}
                        type="secondary"
                        onClick={() => {
                          if (currentSection !== "SHOW_MEAL_PLAN") {
                            setTempMealPlan(undefined);
                            setCurrentSection("SHOW_MEAL_PLAN");
                          } else {
                            setShowDisableMealPlanModal(true);
                          }
                        }}
                        size="small"
                      />
                    </>
                  )}
                </div>
                <div className="patient__details__personal">
                  <div className="patient__details__personal__title">
                    <h3>Informações pessoais</h3>
                  </div>
                  <div className="patient__details__personal__forms">
                    <TextInput
                      label="Data de nascimento"
                      placeholder="Data de nascimento"
                      value={patient?.birth_date}
                    />
                    <div className="patient__details__personal__forms__measurements">
                      <TextInput
                        label="Peso"
                        placeholder="Peso"
                        value={`${patient?.body_measurement?.weight ?? ''}`}
                      />
                      <TextInput
                        label="Altura"
                        placeholder="Altura"
                        value={`${patient?.body_measurement?.height || ''}`}
                      />
                    </div>
                    <DefaultDropdown
                      label="Nível de atividade física"
                      value={formatActivityFactor(patient?.physical_activity_factor)}
                      placeholder="Nível de atividade física"
                      options={[
                        { value: "SEDENTARY", label: "Sedentário" },
                        { value: "LIGHTLY_ACTIVE", label: "Pouco ativo" },
                        { value: "MODERATELY_ACTIVE", label: "Moderadamente ativo" },
                        { value: "VERY_ACTIVE", label: "Muito ativo" },
                        { value: "EXTRA_ACTIVE", label: "Super ativo" },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="patient__meal-plan">
                <div className="patient__meal-plan__title">
                  <div className="patient__meal-plan__title__left">
                    <h3>
                      {getSectionTitle()}
                    </h3>
                    <p className="patient-meal-plan__update">
                      {currentSection === "SHOW_MEAL_PLAN" ? getLastUpdateDateDescription() : `Total de calorias: `}
                      {currentSection !== "SHOW_MEAL_PLAN" && (<b>{getTotalOfCalories()}</b>)}
                    </p>
                  </div>
                  {(currentSection === "EDIT_MEAL_PLAN" || currentSection === "CREATE_MEAL_PLAN") && (
                    <div className="patient__meal-plan__title__actions">
                      <DefaultButton
                        title="Nova refeição"
                        type="tertiary"
                        onClick={() => {
                          setTempMealPlan({
                            ...tempMealPlan,
                            meal_periods: [...tempMealPlan.meal_periods, { period: undefined, meal_foodstuffs: [] }],
                          });
                        }}
                        disabled={hasIncompleteMealPeriod()}
                        size="small"
                      />
                    </div>
                  )}
                  {currentSection === "SHOW_MEAL_PLAN" && (tempMealPlan || mealPlan) && (
                    <p className="patient__meal-plan__total-calories patient__meal-plan__total-calories--right">
                      Total de calorias: <b>{getTotalOfCalories()}</b>
                    </p>
                  )}
                </div>
                {!mealPlan && (
                  <div className="patient__meal-plan__content--empty">
                    <div className="patient__meal-plan__content__empty">
                      <img src={MealPlanIcon} alt="Meal Plan Icon" />
                      <p>Paciente ainda não possui um plano alimentar.</p>
                      <DefaultButton
                        title="Criar plano alimentar"
                        type="tertiary"
                        onClick={() => {
                          setTempMealPlan({
                            meal_periods: [
                              { period: undefined, meal_foodstuffs: [] },
                            ],
                          });
                          setCurrentSection("CREATE_MEAL_PLAN");
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                )}
                {mealPlan && currentSection === "SHOW_MEAL_PLAN" && (
                  <div className="patient__meal-plan__content">
                    <div className="patient__meal-plan__content__meals">
                      {mealPlan?.meal_periods?.map((mealPeriod) => (
                        <div className="patient__meal-plan__content__meal">
                          <div className="patient__meal-plan__content__meal__header">
                            <DefaultDropdown
                              label="Refeição"
                              placeholder="Refeição"
                              value={mealPeriod?.period ? formatMealName(mealPeriod.period) : ''}
                              options={availableMealOptions}
                              onSelect={(value) => {
                                setMealPlan({
                                  ...mealPlan,
                                  meal_periods: mealPlan.meal_periods.map((item) => item.period === mealPeriod.period ? { ...item, period: value } : item),
                                });
                              }}
                            />
                          </div>
                          <div className="patient__meal-plan__content__meal__foodstuffs">
                            <div className="patient__meal-plan__content__meal__foodstuffs__title">
                              <p>Alimentos</p>
                            </div>
                            <div className="patient__meal-plan__content__meal__foodstuffs__items">
                              {mealPeriod.meal_foodstuffs.map((meal) => (
                                <div className="patient__meal-plan__content__meal__foodstuffs__item">
                                  <p>{formatFoodstuffTitle(meal)}</p>
                                </div>
                              ))}
                            </div>
                            <div className="patient__meal-period__metrics">
                              <div className="patient__meal-period__metrics__title">
                                <h4>Informações nutricionais</h4>
                              </div>
                              <div className="patient__meal-period__metrics__content">
                                <Metric
                                  icon={CaloriesIcon} title="Calorias" value={calculateTotalCalories(mealPeriod)} />
                                <Metric
                                  icon={ProteinIcon} title="Proteínas" value={`${calculateTotalProteins(mealPeriod)} g`} />
                                <Metric
                                  icon={CarbohydratesIcon} title="Carboidratos" value={`${calculateTotalCarbohydrates(mealPeriod)} g`} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {tempMealPlan && (currentSection === "EDIT_MEAL_PLAN" || currentSection === "CREATE_MEAL_PLAN") && (
                  <div className="patient__meal-plan__content">
                    <div className="patient__meal-plan__content__meals">
                      {tempMealPlan?.meal_periods?.map((mealPeriod) => (
                        <div className="patient__meal-plan__content__meal">
                          <div className="patient__meal-plan__content__meal__header">
                            <DefaultDropdown
                              label="Refeição"
                              placeholder="Refeição"
                              value={mealPeriod?.period ? formatMealName(mealPeriod.period) : ''}
                              options={availableMealOptions}
                              onSelect={(value) => {
                                setTempMealPlan({
                                  ...tempMealPlan,
                                  meal_periods: tempMealPlan.meal_periods.map((item) => item.period === mealPeriod.period ? { ...item, period: value } : item),
                                });
                              }}
                            />
                          </div>
                          <div className="patient__meal-plan__content__meal__foodstuffs">
                            <div className="patient__meal-plan__content__meal__foodstuffs__header">
                              <div className="patient__meal-plan__content__meal__foodstuffs__title">
                                <p>Alimentos</p>
                              </div>
                              <div className="patient__meal-plan__content__meal__foodstuffs__actions">
                                {mealPeriod?.meal_foodstuffs?.length > 0 && (
                                  <a
                                    href="javascript:;"
                                    onClick={() => {
                                      setShowAddFoodstuffModal(true);
                                      setSelectedMealPeriod(mealPeriod.period);
                                    }}
                                  >
                                    Novo alimento
                                  </a>
                                )}
                              </div>
                            </div>
                            <div className="patient__meal-plan__content__meal__foodstuffs__items">
                              {mealPeriod?.meal_foodstuffs?.map((meal) => (
                                <div className="patient__meal-plan__content__meal__foodstuffs__item patient__meal-plan__content__meal__foodstuffs__item--edit">
                                  <p>{formatFoodstuffTitle(meal)}</p>
                                  <a href="javascript:;" onClick={() => handleRemoveFoodstuffFromMealPeriod(mealPeriod, meal)}>
                                    <img src={XSquareIcon} alt="Delete Icon" />
                                  </a>
                                </div>
                              ))}
                              {mealPeriod?.meal_foodstuffs?.length === 0 && (
                                <div className="patient__meal-plan__content__meal__empty">
                                  <img src={PinkDishIcon} alt="Dish Icon" />
                                  <p>Nenhum alimento cadastrado</p>
                                  <DefaultButton
                                    title="Novo alimento"
                                    type="primary"
                                    onClick={() => {
                                      setShowAddFoodstuffModal(true);
                                      setSelectedMealPeriod(mealPeriod.period);
                                    }}
                                    size="small"
                                  />
                                </div>
                              )}
                              {mealPeriod?.meal_foodstuffs?.length > 0 && (
                                <div className="patient__meal-period__metrics">
                                  <div className="patient__meal-period__metrics__title">
                                    <h4>Informações nutricionais</h4>
                                  </div>
                                  <div className="patient__meal-period__metrics__content">
                                    <Metric
                                      icon={CaloriesIcon} title="Calorias" value={calculateTotalCalories(mealPeriod)} />
                                    <Metric
                                      icon={ProteinIcon} title="Proteínas" value={`${calculateTotalProteins(mealPeriod)} g`} />
                                    <Metric
                                      icon={CarbohydratesIcon} title="Carboidratos" value={`${calculateTotalCarbohydrates(mealPeriod)} g`} />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <DefaultModal
        showModal={showAddFoodstuffModal}
        title="Novo alimento"
        className="add-foodstuff-modal"
        onClose={() => {
          setShowAddFoodstuffModal(false);
          setSelectedFoodstuff(undefined);
        }}
      >
        <div className="add-foodstuff-modal__content">
          <DefaultDropdown
            label="Buscar alimento"
            placeholder="Buscar alimento"
            value={selectedFoodstuff?.name || ""}
            options={
              searchedFoodstuffs.map((foodstuff) => ({
                value: foodstuff.id,
                label: foodstuff.name,
              }))
            }
            onSelect={(value) => {
              setSelectedFoodstuff(searchedFoodstuffs.find((foodstuff) => foodstuff.id === value));
            }}
            searchPlaceholder="Buscar alimento"
            onSearch={(text) => fetchFoodstuffs(text)}
          />
          {selectedFoodstuff && (
            <div className="add-foodstuff-modal__inputs">
              <TextInput
                type="number"
                label="Peso base (g)"
                placeholder="Peso base (g)"
                onChangeText={(text) => setSelectedFoodstuff({ ...selectedFoodstuff, baseline_weight: text })}
                value={`${selectedFoodstuff.baseline_weight || ''}`}
              />
              <TextInput
                label="Calorias"
                placeholder="Calorias"
                onChangeText={(text) => { }}
                value={`${selectedFoodstuff?.nutrition_information?.calories || '0'}`}
              />
              {selectedFoodstuff.unit_of_measurement === 'UNITS' && (
                <TextInput
                  label="Quantidade"
                  placeholder="Quantidade"
                  type="number"
                  onChangeText={(text) => setSelectedFoodstuff({ ...selectedFoodstuff, quantity: text })}
                  value={selectedFoodstuff.quantity}
                />
              )}
            </div>
          )}
        </div>
        <div className="add-foodstuff-modal__buttons">
          <div className="add-foodstuff-modal__button">
            <DefaultButton
              title="Cancelar"
              onClick={() => {
                setShowAddFoodstuffModal(false);
                setSelectedFoodstuff(undefined);
                setSelectedMealPeriod(undefined);
              }}
              type="secondary"
              size="medium"
            />
          </div>
          <div className="add-foodstuff-modal__button">
            <DefaultButton
              title="Adicionar"
              onClick={() => handleAddSelectedFoodstuffToMealPeriod()}
              type="primary"
              size="medium"
              disabled={selectedFoodstuff?.unit_of_measurement === 'UNITS' ? !selectedFoodstuff.quantity : false}
            />
          </div>
        </div>
      </DefaultModal>
      <DefaultModal
        title="Desativar plano alimentar"
        showModal={showDisableMealPlanModal}
        onClose={() => setShowDisableMealPlanModal(false)}
        className="manage-availability-modal"
      >
        <div className="manage-availability-modal__content">
          <div className="manage-availability-modal__description">
            <p>Você tem certeza que deseja desativar este plano alimentar? O paciente será notificado, e esta ação não poderá ser desfeita.</p>
          </div>
          <div className="manage-availability-modal__actions">
            <div className="manage-availability-modal__button">
              <DefaultButton
                title="Voltar"
                type="secondary"
                onClick={() => setShowDisableMealPlanModal(false)}
                size="small"
              />
            </div>
            <div className="manage-availability-modal__button">
              <DefaultButton
                title="Confirmar"
                type="primary"
                onClick={() => handleDisableMealPlan(mealPlan)}
                size="small"
              />
            </div>
          </div>
        </div>
      </DefaultModal>
      <NotificationBar
        show={notificationBar?.title}
        title={notificationBar?.title}
        durationInSeconds={4}
        onClose={() => setNotificationBar(null)}
      />
    </>
  );
};

export default Patient;

Patient.propTypes = {};
