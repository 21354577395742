import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const mealPlanService = {
  async createMealPlan(nutritionistId, patientId, mealPlan) {
    const token = localStorage.getItem("token");
    const body = {
      ...mealPlan,
      nutritionist_id: nutritionistId,
      patient_id: patientId,
    }

    try {
      const response = await api.post(`/v1/meal-plans`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao criar plano alimentar");
    }
  },

  async updateMealPlan(mealPlan) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.put(`/v1/meal-plans/${mealPlan.id}`, mealPlan, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao atualizar plano alimentar");
    }
  },
};

export default mealPlanService;